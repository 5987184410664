import axios from 'axios';

const API_URL = 'http://localhost:8089/api';

class AuthService {
    login(data) {
		console.log("auth service - login antes do post");

        return axios.post(`${API_URL}/login`, data);
    }

    register(data) {
        return axios.post(`${API_URL}/register`, data);
    }
}

export default new AuthService();


/*import fetch from 'auth/FetchInterceptor'

const AuthService = {}

AuthService.login = function (data) {
	return fetch({
		url: '/auth/login',
		method: 'post',
		data: data
	})
}

AuthService.register = function (data) {
	return fetch({
		url: '/auth/register',
		method: 'post',
		data: data
	})
}

AuthService.logout = function () {
	return fetch({
		url: '/auth/logout',
		method: 'post'
	})
}

AuthService.loginInOAuth = function () {
	return fetch({
		url: '/auth/loginInOAuth',
		method: 'post'
	})
}

export default AuthService;
*/