import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import AuthService from 'services/AuthService';

export const initialState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    token: localStorage.getItem(AUTH_TOKEN) || null,
};

export const signIn = createAsyncThunk('auth/login', async (data, { rejectWithValue }) => {
    const { email, senha } = data;
    try {
		console.log("authslice = login antes await");

        const response = await AuthService.login({ email, senha });
        const token = response.data.token;
        localStorage.setItem(AUTH_TOKEN, token);
		console.log("authslice = token "+token);

        return token;
    } catch (err) {
		console.log("authslice = erro "+err);

        return rejectWithValue(err.response?.data?.error || 'Error');
    }
});

export const signUp = createAsyncThunk('auth/register', async (data, { rejectWithValue }) => {
    const { nome, email, telefone, senha } = data;
    try {
        const response = await AuthService.register({ nome, email, telefone, senha });
        const token = response.data.token;
        localStorage.setItem(AUTH_TOKEN, token);
        return token;
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error');
    }
});

export const signOut = createAsyncThunk('auth/logout', async () => {
    localStorage.removeItem(AUTH_TOKEN);
    return null;
});

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        showAuthMessage: (state, action) => {
            state.message = action.payload;
            state.showMessage = true;
            state.loading = false;
        },
        hideAuthMessage: (state) => {
            state.message = '';
            state.showMessage = false;
        },
        showLoading: (state) => {
            state.loading = true;
        },
        signInSuccess: (state, action) => {
            state.loading = false;
            state.token = action.payload;
            state.redirect = '/';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(signIn.pending, (state) => {
                state.loading = true;
            })
            .addCase(signIn.fulfilled, (state, action) => {
                state.loading = false;
                state.token = action.payload;
                state.redirect = '/';
            })
            .addCase(signIn.rejected, (state, action) => {
                state.loading = false;
                state.message = action.payload;
                state.showMessage = true;
            })
            .addCase(signUp.pending, (state) => {
                state.loading = true;
            })
            .addCase(signUp.fulfilled, (state, action) => {
                state.loading = false;
                state.token = action.payload;
                state.redirect = '/';
            })
            .addCase(signUp.rejected, (state, action) => {
                state.loading = false;
                state.message = action.payload;
                state.showMessage = true;
            })
            .addCase(signOut.fulfilled, (state) => {
                state.loading = false;
                state.token = null;
                state.redirect = '/';
            })
            .addCase(signOut.rejected, (state) => {
                state.loading = false;
                state.token = null;
                state.redirect = '/';
            });
    },
});

export const { showAuthMessage, hideAuthMessage, showLoading, signInSuccess } = authSlice.actions;

export default authSlice.reducer;